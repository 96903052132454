<script lang="ts" setup>
import { ROUTE_NAV_APP } from '@/shared/config'
import { ProductTour } from '@/shared/ui/product-tour'

import { productTourEntity } from '@/entities/product-tour'

import { LogoText } from '@/features/logo'
import { UserAvatar } from '@/features/user'

const localePath = useLocalePath()
const productTourStore = productTourEntity.model.useProductTourStore()
const showProductTour = computed(() => productTourStore.currentStep === '[tour-action-feed="1"]')
</script>

<template>
  <div
    class="sticky top-0 z-10 flex shrink-0 grow-0 items-center justify-between bg-white/80 px-16 py-8 backdrop-blur-sm"
  >
    <LogoText />
    <div class="relative flex items-center gap-8">
      <!-- <div class="h-44 text-h3-bold text-gray-800 flex gap-4 items-center justify-center">1 430 ⚡</div> -->
      <NuxtLink tag="div" :to="localePath(ROUTE_NAV_APP.appSettings)">
        <UserAvatar tour-action-feed="1" />
      </NuxtLink>
      <ProductTour
        v-if="showProductTour"
        position="top"
        class="right-0 top-56 w-240"
        @click="productTourEntity.model.nextStep()"
      >
        Твій профіль і всі налаштування плану
      </ProductTour>
    </div>
  </div>
</template>
