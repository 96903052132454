<script setup lang="ts">
import { ROUTE_NAV_APP } from '@/shared/config'

import AppNavbarItem from './AppNavbarItem.vue'

const localePath = useLocalePath()
</script>

<template>
  <nav class="grid shrink-0 grow-0 grid-cols-3 border-t-1 border-solid border-gray-100 bg-white">
    <AppNavbarItem :to="localePath(ROUTE_NAV_APP.appActionFeed)">
      <template #icon>
        <Icon class="text-gray-900" mode="svg" size="24" name="material-symbols-light:directions-run" />
      </template>
      <template #text> {{ $t('app.nav.action_feed') }} </template>
    </AppNavbarItem>
    <AppNavbarItem :to="localePath(ROUTE_NAV_APP.appAnalytics)">
      <template #icon>
        <Icon class="text-gray-900" mode="svg" size="24" name="material-symbols-light:chart-data-outline" />
      </template>
      <template #text> {{ $t('app.nav.analytics') }} </template>
    </AppNavbarItem>
    <AppNavbarItem :to="localePath(ROUTE_NAV_APP.appCommunity)">
      <template #icon>
        <Icon class="text-gray-900" mode="svg" size="24" name="material-symbols-light:comedy-mask" />
      </template>
      <template #text> {{ $t('app.nav.community') }} </template>
    </AppNavbarItem>
  </nav>
</template>
