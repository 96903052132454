<script setup lang="ts">
import { ButtonSuccess } from '@/shared/ui/button'

import type { paymentEntity } from '@/entities/payment'

import BundleCard from './bundles/BundleCard.vue'

const DEFAULT_BUNDLE: paymentEntity.types.PaymentBundle = 'base_4200_annual'

const props = defineProps({
  bundle: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['bundle', 'purchase'])

onMounted(() => {
  if (!props.bundle) emit('bundle', DEFAULT_BUNDLE)
})
</script>

<template>
  <img src="/payment/bundles-fill.webp" class="skeleton mb-24 h-260 shrink-0 grow-0 bg-gray-100 object-cover" alt="" />
  <div class="mt-12 flex shrink grow flex-col justify-between px-16">
    <div class="grid w-full grid-cols-3 gap-12">
      <BundleCard
        :active="props.bundle === 'base_500_monthly'"
        :months-count="1"
        months-text="місяц"
        price="500 грн/мес"
        @click="emit('bundle', 'base_500_monthly')"
      />
      <BundleCard
        :active="props.bundle === 'base_4200_annual'"
        :months-count="12"
        months-text="місяців"
        discount-text="Знижка 30%"
        price="4200 грн/рік"
        @click="emit('bundle', 'base_4200_annual')"
      />
      <BundleCard
        :active="props.bundle === 'base_1350_quarterly'"
        :months-count="3"
        months-text="місяці"
        discount-text="Знижка 15%"
        price="1350 грн/рік"
        @click="emit('bundle', 'base_1350_quarterly')"
      />
    </div>
    <ButtonSuccess class="mb-24 w-full" :disabled="!props.bundle" @click="emit('purchase')">Оплатити</ButtonSuccess>
  </div>
</template>
