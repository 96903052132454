<script setup lang="ts">
const props = defineProps({
  to: {
    type: String,
    required: true,
  },
})

const route = useRoute()
const isActive = computed(() => route.path.indexOf(props.to) === 0)
</script>

<template>
  <NuxtLink
    tag="div"
    class="flex h-72 cursor-pointer flex-col items-center justify-center lg:hover:bg-gray-100"
    :to="to"
  >
    <div
      class="mb-2 h-24 w-24 text-center leading-none"
      style="font-size: 24px"
      :class="{ 'opacity-100': isActive, 'opacity-30': !isActive }"
    >
      <slot name="icon" />
    </div>
    <div class="text-gray-900" :class="{ 'text-h7-semibold': isActive, 'text-h7-medium': !isActive }">
      <slot name="text" />
    </div>
  </NuxtLink>
</template>
