<script setup lang="ts">
import { ROUTE_NAV_APP } from '@/shared/config'

import { paymentEntity } from '@/entities/payment'

import { AppCloseButton } from '@/features/app'

import PaymentBaseBundles from './PaymentBaseBundles.vue'

import type { PaymentState } from '../types'

const state = reactive<PaymentState>({
  show: false,
  entry: '',
  screen: '',
  bundle: '',
})

const modal = useModal()
const router = useRouter()
const route = useRoute()
const localePath = useLocalePath()
const paymentStore = paymentEntity.model.usePaymentStore()

modal.on('payment_open', (payload) => {
  if (state.show) return
  paymentStore.$reset()
  paymentStore.cacheClear()
  state.entry = payload.entry
  state.bundle = 'base_4200_annual'
  state.screen = payload.screen || 'base_bundles'
  state.show = true
})

function onClose() {
  state.show = false
}

function onPurchase() {
  paymentStore.step = 'bundles'
  paymentStore.bundle = state.bundle
  paymentStore.entry = state.entry
  paymentStore.entryPath = route.path
  paymentStore.cacheSave()
  state.show = false
  router.push(localePath(ROUTE_NAV_APP.appPayment))
}
</script>

<template>
  <Transition name="fade">
    <div
      v-if="state.show"
      class="fixed inset-0 flex flex-col overflow-x-hidden overflow-y-scroll overscroll-none bg-white"
    >
      <AppCloseButton class="absolute right-16 top-16" @click="onClose" />
      <PaymentBaseBundles
        v-if="state.screen === 'base_bundles'"
        :bundle="state.bundle"
        @bundle="state.bundle = $event"
        @purchase="onPurchase"
      />
    </div>
  </Transition>
</template>
