<script setup lang="ts">
import { ROUTE_NAV_APP } from '@/shared/config'

import { appEntity } from '@/entities/app'
import { paymentEntity } from '@/entities/payment'
// import { productTourEntity } from '@/entities/product-tour'
import { userEntity } from '@/entities/user'

import { AppRouterOverlay } from '@/features/app'

import { AppHeader, AppNavbar } from '@/widgets/app'
import { PaymentModal } from '@/widgets/payment-modal'

definePageMeta({
  pageTransition: false,
})

const router = useRouter()
const route = useRoute()
const { initialize } = useHotjar()
const localePath = useLocalePath()
const appStore = appEntity.model.useAppStore()
// const productTourStore = productTourEntity.model.useProductTourStore()
const apiUnauthorized = useApiErrorUnauthorized(onErrorCallback)
const billingStartApp = paymentEntity.model.useBillingStartApp()
const scrollNode = ref<HTMLDivElement>()
// const showProductTourBg = computed(() => !!productTourStore.currentStep)

onBeforeMount(() => {
  apiUnauthorized.on()
})

onMounted(async () => {
  initialize()
  appStore.ready = await userEntity.model.fetchMe()
  if (!appStore.ready) return
  if (route.path === '/app') {
    router.replace(localePath(ROUTE_NAV_APP.appActionFeed))
  }
  await billingStartApp()
})

onBeforeUnmount(() => {
  apiUnauthorized.off()
})

router.afterEach(async () => {
  if (!scrollNode.value) return
  await nextTick()
  scrollNode.value.scrollTop = 0
})

function onErrorCallback() {
  router.push(localePath(ROUTE_NAV_APP.authLogin))
}
</script>

<template>
  <ClientOnly>
    <div class="absolute inset-0">
      <div class="fixed inset-0 mx-auto flex max-w-480 select-none flex-col">
        <div
          id="app-scroll-content"
          ref="scrollNode"
          class="shrink grow overflow-x-hidden overflow-y-scroll overscroll-none"
        >
          <AppHeader v-if="appStore.showHeader" />
          <NuxtPage />
        </div>
        <div id="pre-footer" />
        <AppNavbar v-if="appStore.showNav" />
        <!-- <div v-if="showProductTourBg" class="absolute inset-0 z-10 bg-black/80" /> -->
      </div>
      <AppRouterOverlay v-if="false" />
    </div>
    <PaymentModal />
  </ClientOnly>
</template>
